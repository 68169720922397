import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import config from "../config";



const poolData = {
  UserPoolId: config.UST_POOL_ID,
  ClientId: config.UST_CLIENT_ID,
  Storage: new CookieStorage({secure: false, domain: config.UST_COOKIE_DOMAIN}),
};

export default new CognitoUserPool(poolData);