import axios from 'axios';
import { history } from '../redux/store';
import api from '../config';
const axiosApi = axios.create({
  baseURL: api.API_GATEWAY
})

axiosApi.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    resolve(response.data);
  });
}, error => {
  
  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.APP_URL;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

const axiosApiGateway = axios.create({
  baseURL: api.API_GATEWAY
});

axiosApiGateway.interceptors.response.use((response: any) => {
  return new Promise((resolve, reject) => {
    console.log({response});
    resolve(JSON.parse(response.data.data));
  });
}, error => {
  console.log(error);
  if (!error.response) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
    console.log(error.response.status);
  if ([401, 403].indexOf(error.response.status) > -1) {
    window.location.href = api.APP_URL;

  } else {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
})

export {
  axiosApi,
  axiosApiGateway
}