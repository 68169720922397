const envJson = require(`./environments/env.${process.env.REACT_APP_APP}.${process.env.REACT_APP_ENV}.json`);

const config = {
    API_GATEWAY: envJson.API_GATEWAY,
    APP_URL: envJson.APP_URL,
    BUCKET_NAME: envJson.BUCKET_NAME,
    STREAM_URL: envJson.STREAM_URL,
    REDIRECTION_UPON_COMPLETION: envJson.REDIRECTION_UPON_COMPLETION,
    UST_COOKIE_DOMAIN: envJson.UST_COOKIE_DOMAIN,
    UST_POOL_ID: envJson.UST_POOL_ID,
    UST_CLIENT_ID: envJson.UST_CLIENT_ID,
    UST_REDIRECT_DASHBOARD: envJson.UST_REDIRECT_DASHBOARD,
    UST_REDIRECT_ACCOUNT: envJson.UST_REDIRECT_ACCOUNT,

    credentials: {
        accessKeyId: envJson.ACCESS_KEY_ID,
        secretAccessKey: envJson.SECRET_ACCESS_KEY,
        region: envJson.REGION,
    }
};

export default config;
