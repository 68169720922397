import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { SET_PROFILE, LOGIN_REQUEST } from './types';


export function* loginRequest() {
  yield takeEvery(LOGIN_REQUEST, function* () : SagaIterator {
    try {
      const response = yield call(login);
      const accessToken: string = response.accessToken;
      const profile: Object = response.user;
      yield put({
        type: SET_PROFILE,
        payload: {
          accessToken,
          profile
        }
      });
    } catch (error) {
    }
  });
}

function login(): any {
  const info: Object = {
    user: {
      name: 'umair',
      email: "umair.riaz@exponentoc.com",
      password: "123456",
    },
    accessToken: "q11cr1234"
  }
  return info;
}


export default function* rootSaga() {
  yield all([
    fork(loginRequest)
  ]);
}
