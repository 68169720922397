
import { Reducer } from "redux";
import { LOADED_SUCCESS, SOCKET_CONNECTED, LOADING_APP } from './types';

interface actionType {
  type: string,
  payload?: any
}

interface initalStateProps {
  loaded: boolean,
  socketConnected: boolean;
  isFreeUser: boolean;
  subscription: string | null;
}

const initState: initalStateProps = {
  loaded: false,
  socketConnected: false,
  isFreeUser: false,
  subscription: null
}

const appReducer: Reducer<initalStateProps, actionType> = (state = initState, action) => {
  switch (action.type) {
    case LOADED_SUCCESS:
      return Object.assign({}, state, {
        loaded: true
      });
    case LOADING_APP:
      return Object.assign({}, state, {
        loaded: false
      });
    case SOCKET_CONNECTED:
      console.log(action.payload);
      return Object.assign({}, state, {
        socketConnected: true,
        isFreeUser: !['AGENCYOWNER', 'AGENCYUSER'].includes(action.payload.res.subscription),
        subscription: action.payload.res.subscription,
        loaded: true
      });
    default:
      return state;
  }
}

export default appReducer;