export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const SET_PROFILE = 'SET_PROFILE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST'

interface IProps{
    CHECK_AUTHORIZATION: typeof CHECK_AUTHORIZATION;
    SET_PROFILE: typeof SET_PROFILE;
    LOGIN_REQUEST: typeof LOGIN_REQUEST;
}

export type actionType = IProps