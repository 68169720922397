import React from 'react';
import LoadingIcon from "../loading-icon";
import './style.css';

export default function LoadingComponent(props:any){
    return(
        <div>
            <LoadingIcon />
        </div>
    );
}