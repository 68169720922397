import React from 'react';
import './404.css';

function NotFound(props) {
  return (
    <div className="not-found">404 Not found!</div>
  );
}

export default NotFound;
