import React from 'react';

import './style.css';

export default function LoadingIcon() {

    return (
        <>
            <div id="spinner" className="page-loader">
                <div className="d-flex flex-column align-items-center">
                    <div className="mb-3" style={{ width: "70px" }} id="square6">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <h2 className="loading-title">Hang tight!</h2>
                    <p>Wait a sec...uploading video</p>
                </div>
            </div>
        </>
    )
}
