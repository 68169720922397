// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import {
  Dropdown,
  Menu,
  Button,
  Card,
  Modal,
  Input,
  Typography,
  Tooltip
} from 'antd';
import { callApi } from './helper/api.helper';
import { CheckCircleOutlined, AudioOutlined, AudioMutedOutlined, CopyOutlined, SettingOutlined, CloudDownloadOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { from, fromEvent } from 'rxjs';
import NotFound from './404';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { switchMap, takeUntil, map, concatMap, toArray } from 'rxjs/operators';
import classnames from 'classnames';
import qs from 'qs';
import { store } from './redux/store';
import { SET_PROFILE } from './redux/auth/types';
import { LOADED_SUCCESS } from './redux/app/types';
import { getSession } from './helper/ust.helper';
import { UserModel } from './model/user.model';
import { Provider } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import LoadingComponent from './components/LoadingComponent';

let obs: any;
let uploadId: any;
declare const window: any;
const config: any = require('./config').default;
const isMediaRecorderSupported: any = window?.MediaRecorder;
const { Paragraph } = Typography;
const { SubMenu } = Menu;
const credentials = { credentials: config.credentials };
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const { Meta } = Card;

let s3: any;
let s3Key: string;

let mediaRecorder: any;
let stopwatchInterval: any;
const chunks: any = [];
let currentUserId: string;


const bootApp = async () => {
  await getSession()
    .then(session => {
      const parsedData = new UserModel(
        session,
        session.idToken.jwtToken,
      );
      currentUserId = parsedData.userId;
      store.dispatch({ type: SET_PROFILE, payload: parsedData });
      store.dispatch({ type: LOADED_SUCCESS });
    })
    .catch(err => {
      console.log('error while user authentication', err);
      // window.location.href = config.APP_URL;
    });
  // store.dispatch({type: LOADED_SUCCESS});

  // boot(res.user_id, res.first_name + res.last_name, jwt);
};


function App() {
  const [webcam, setWebcam] = useState(false);
  const [screenRecording, setScreenRecording] = useState(false);
  const [state, setState] = useState('');
  const [localStream1, setLocalStream1] = useState<any>(null);
  const [localStream2, setLocalStream2] = useState<any>();
  const [uploadStream, setUploadStream] = useState<any>();
  // eslint-disable-next-line
  const [audioStream, setAudioStream] = useState<any>();
  const [bytesRecorded, setBytesRecorded] = useState(0);
  const [bytesUploaded, setBytesUploaded] = useState(0);
  const [link, setLink] = useState<any>();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [player, setPlayer] = useState(false);
  const [playerSource, setPlayerSource] = useState('');
  const [webcamScreenRecording, setWebcamScreenRecording] = useState(false);
  const video = useRef<any>(0);
  const video2 = useRef<any>(0);
  const canvas = useRef<any>(null);
  const copyText = useRef<any>();
  const [copied, setCopied] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [selectedMic, setSelectedMic] = useState('');
  const [stopwatch, setStopwatch] = useState<string>('00:00');
  const [togglePlayPause, setTogglePlayPause] = useState(false);
  const [askReset, setAskReset] = useState(false);
  const [mute, setMute] = useState<boolean | undefined>();

  const [cameras, setCameras] = useState<any>([]);
  const [mics, setMics] = useState<any>([]);
  const [counter, setCounter] = useState(0);
  const [panel, setPanel] = useState(false);
  const [recordingState, setRecordingState] = useState<boolean|null>(null);
  const layout = useRef<any>(null);

  const url = window.location.href;
  const idx = url.indexOf('?');
  const params = (idx != -1) ? qs.parse(url.slice(idx + 1)) : {};

  useEffect(() => {
    let modalCross = document.querySelector<HTMLElement>('.ant-modal-close-x');
    if (!!(+panel) && modalCross) {
      modalCross.style.display = 'none';
    }
  }, [modalVisibility])


  useEffect(() => {
    const message = JSON.stringify({
      source: 'vumu-embed',
      payload: {
        state: state === 'active' || state === 'busy' || state === 'completed' ? false : true
      }
    });
    window.parent.postMessage(message, '*');
  }, [state]);

  useEffect(() => {
    bootApp();
    navigator.mediaDevices.enumerateDevices().then(devices => {

      setMics(devices
          .filter((d: any) => d.kind === 'audioinput')
          .map((d: any, i: number) => ({
            value: d.deviceId,
            label: d.label || `Microphone ${i + 1}`
          })));

      setCameras(devices
          .filter((d: any) => d.kind === 'videoinput')
          .map((d: any, i: number) => ({
            value: d.deviceId,
            label: d.label || `Camera ${i + 1}`
          })));
    });

    window.addEventListener('resize', () => onResizeLayout(layout, video));
  }, [])

  useEffect(() => {
    if(params.t) {
      switch (params.t) {
        case '1':
          return onScreenOptionClick();
        case '2':
          onWebcamOptionClick();
          return;
        case '3':
          onWebcamScreenOptionClick();
          return;
      }
    } else {
      setPanel(!!(params.panel || ''));

      if(params.src != 'wf') {
        setModalVisibility(!!(params.panel || ''));
      }
    }
  }, []);

  useEffect(() => {
    if(video.current) {
      video.current.srcObject = localStream1;

      const _event = () => {
        onResizeLayout(layout, video);
        video.current.removeEventListener("playing", _event);
      }
      video.current.addEventListener("playing", _event);

      setPlayer(!localStream1);
    }

    if(video2.current) {
      video2.current.srcObject = localStream2;
    }

    if(!video.current && !video2.current) {
      setPlayer(true);
    }

  }, [localStream1, localStream2]);

  const drawStreams = (element1: any, element2: any) => {
    // const mirrorRadius = 30 * canvas.current.width / 100;
    // console.log('desired width', canvas.current.width);
    (function loop(el1: any, el2: any) {
      const video = el1.target;

      if(webcam) {
        canvas.current.width = video.offsetWidth;
        canvas.current.height = video.offsetHeight;
      } else {
        canvas.current.width = video.videoWidth;
        canvas.current.height = video.videoHeight;
      }

      const ctx = canvas.current.getContext('2d');
      const mirrorRadius = ((((220 * 3) < video.width) && ((220 * 3) < video.height)) ? 220 : 220 / 2);

      if (!video.paused && !video.ended) {

        ctx.save(); // Rotate the video
        ctx.translate(video.videoWidth / 2, video.videoHeight / 2);

        if (el1.flip)
          ctx.scale(-1, 1);

        ctx.drawImage(video, -video.videoWidth / 2, -video.videoHeight / 2); // draw the image
        ctx.restore(); // Restore the last saved state

        const video2 = el2 && el2.target;

        if (video2) {
          ctx.save();

          if (el2.flip)
            ctx.scale(-1, 1);

          ctx.beginPath();
          // * (canvas.current.height / video.current.height)

          const normalizerx = (canvas.current.width / video.offsetWidth);
          const normalizery = (canvas.current.height / video.offsetHeight);
          const xpadding = ((mirrorRadius < 100 ? 20 : 40) * normalizerx);
          const ypadding = ((mirrorRadius < 100 ? 60 : 90) * normalizery);

          ctx.arc(
              -((mirrorRadius * normalizerx) + xpadding),
              ((canvas.current.height + (mirrorRadius * normalizery)) - ((mirrorRadius * normalizery) * 2)) - ypadding,    // y
              mirrorRadius * (canvas.current.height / video.offsetHeight),
              0, Math.PI * 2, true);

          ctx.closePath();
          ctx.clip();

          const video2Ratio = (video2.videoWidth / video2.videoHeight);

          console.log();
          ctx.drawImage(video2,
              -((mirrorRadius * normalizerx * 2) + xpadding + (((video2Ratio * 2 * mirrorRadius * normalizery) - (2 * mirrorRadius * normalizerx)) / 2)),
              ((canvas.current.height + (mirrorRadius * 2 * normalizery)) - ((mirrorRadius * normalizery * 2) * 2)) - ypadding,
              ((2 * mirrorRadius * normalizerx)) * video2Ratio,
              2 * mirrorRadius * normalizery);

          ctx.restore(); // Restore the last saved state
        }

        setTimeout(() => loop(element1, element2), 60); // drawing at 60fps
      }
    })(element1, element2);

    setUploadStream(canvas.current.captureStream());
  }

  const onResizeLayout = (layout: any, video: any) => {
    if(!layout.current || !video.current) return;

    const parentNode = layout.current.parentNode;
    layout.current.style.width = '100%';
    layout.current.style.height = '100%';

    video.current.style.width = 'inherit';
    video.current.style.height = 'initial';

    if(video.current.offsetHeight > layout.current.offsetHeight) {
      video.current.style.height = 'inherit';
      video.current.style.width = 'initial';
    }

    layout.current.style.width = Math.min(parentNode.offsetWidth, video.current.offsetWidth) + 'px';
    layout.current.style.height = Math.min(parentNode.offsetHeight, video.current.offsetHeight) + 'px';

    window.parent.postMessage({
      type: "layout-resize",
      payload: {
        width: layout.current.offsetWidth,
        height: layout.current.offsetHeight
      }
    }, '*');
  }

  const prepareWebcamRecording = (): any=> {
    setLink('');
    setCopied(false);

    const constraints: any = {
      video: {
        width: layout.current.offsetWidth, height: layout.current.offsetHeight
      },
      audio: true
    };

    if(!selectedMic) {
      mics.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedMic(c.value));
    }

    if(selectedCamera) {
      constraints.video.deviceId = { exact: selectedCamera };
    } else {
      cameras.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedCamera(c.value));
      constraints.video.facingMode = 'environment';
    }

    const deffered = navigator.mediaDevices
        .getUserMedia(constraints);

    deffered.then(stream => {



      console.log("Successfully received user media.");
      setLocalStream1(stream);
    })
    .catch(error => {
      alert('Please allow camera/audio permssion and refresh page.');
      console.error("navigator.getUserMedia error: ", error)
    });

    return deffered;
  }

  const onWebcamOptionClick = () => {
    // if(!cameras.length) {
    //   alert('No camera found!');
    // } else {
      setWebcam(true);
      setModalVisibility(false);
      setPlayer(false);
      prepareWebcamRecording();
    // }
  }

  const onWebcamRecordClick = () => {
    if(!state || state === 'completed') {


      setRecordingState(true);

      const constraints: any = {};
      if(mute) {
        constraints.audio = false;
      } else {
        if(selectedMic) {
          constraints.audio = {
            deviceId: { exact: selectedMic }
          }
        } else {
          constraints.audio = true;
          mics.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedMic(c.value));
        }
      }

      if(constraints.audio) {
        navigator.mediaDevices.getUserMedia({ video: false, audio: constraints.audio }).then((voiceStream) => {
          const tracks = [ ...uploadStream.getVideoTracks(), ...mergeStreams(uploadStream, voiceStream) ];

          const stream: any = new MediaStream(tracks);

          setAudioStream(voiceStream);

          startRecordingInternal(stream);

        }).catch((error: any) => {
          alert('Please allow microphone permssion then refresh page.');
          console.error("navigator.getUserMedia error: ", error);
        });
      } else {
        startRecordingInternal(uploadStream);
      }
    } else {
      if(params.src == 'wf') {
        const message = JSON.stringify({
          source: 'vumu-embed',
          payload: {
            shareLink: window.location.origin + '/share/' + encodeURIComponent(s3Key.replace('.webm', ''))
          }
        });
        window.parent.postMessage(message, '*');
      } else {
        setRecordingState(false);
      }

      video.current.srcObject = null;
      setState('busy');
      stopStopwatch();
      localStream1.getTracks().forEach((track: any) => track.stop());
      uploadStream.getTracks().forEach((track: any) => track.stop());
      if (mediaRecorder.state !== 'inactive') mediaRecorder.stop();
      setPlayer(true);
      setTimeout(exportVideo, 0);
    }
  };

  const onRerecordWebcam = () => {
    stopStopwatch();
    setState('');
    prepareWebcamRecording();
  }

  const prepareScreenRecording = () => {
    return new Promise((resolve, reject) => {
      setPlayer(false);
      setLink('');
      setCopied(false);

      if(!selectedMic) {
        mics.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedMic(c.value));
      }

      // @ts-ignore
      navigator.mediaDevices.getDisplayMedia({
        video: {
          // @ts-ignore
          mediaSource: "screen",
          width: layout.current.offsetWidth,
          height: layout.current.offsetHeight
        },
        audio: false }).then((desktopStream: any) => {

        console.log("Successfully received user media.");
        setLocalStream1(desktopStream);
        resolve(desktopStream);
      })
      .catch((error: any) => {
        console.error("navigator.getUserMedia error: ", error)
        reset();

        if(params.src === 'wf') {
          sendMessage({ menu: true });
        } else {
          setModalVisibility(true);
        }
        reject();
      });
    });
  }

  const sendMessage = (payload = {}) => {
    const message = JSON.stringify({
      source: 'vumu-embed',
      payload
    });
    window.parent.postMessage(message, '*');
  }

  const onScreenOptionClick = () => {
    setScreenRecording(true);
    setModalVisibility(false);
    setPlayer(false);
    setLink('');
    prepareScreenRecording();
  }

  const onScreenRecordClick: any = (localStream1: any) => {
    if(!state || state === 'completed') {
      setRecordingState(true);
      const constraints: any = {};
      if(mute) {
        constraints.audio = false;
      } else {
        if(selectedMic) {
          constraints.audio = {
            deviceId: { exact: selectedMic }
          }
        } else {
          constraints.audio = true;
          mics.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedMic(c.value));
        }
      }

      if(constraints.audio) {
        navigator.mediaDevices.getUserMedia({ video: false, audio: constraints.audio }).then((voiceStream) => {

          const tracks = [
            ...uploadStream.getVideoTracks(),
            ...mergeStreams(uploadStream, voiceStream)
          ];



          const stream: any = new MediaStream(tracks);

          setAudioStream(voiceStream);
          startRecordingInternal(stream);

        }).catch((error: any) => {
          alert('Please allow Microphone permssion then refresh page.');
          console.error("navigator.getUserMedia error: ", error);
        });
      } else {
        startRecordingInternal(localStream1);
      }

    } else {

      if(params.src == 'wf') {
        const message = JSON.stringify({
          source: 'vumu-embed',
          payload: {
            shareLink: window.location.origin + '/share/' + encodeURIComponent(s3Key.replace('.webm', ''))
          }
        });
        window.parent.postMessage(message, '*');
      } else {
        setRecordingState(false);
      }

      video.current.srcObject = null;
      stopStopwatch();
      setState('busy');
      localStream1.getTracks().forEach((track: any) => track.stop());
      uploadStream.getTracks().forEach((track: any) => track.stop());
      if(mediaRecorder.state !== 'inactive') mediaRecorder.stop();
      setPlayer(true);
      setTimeout(exportVideo, 0);
    }
  }

  const onRerecordScreen = () => {
    stopStopwatch();
    setState('');
    prepareScreenRecording();
  }

  const prepareWebcamScreenRecording = () => {
    return new Promise((resolve, reject) => {
      setLink('');
      setCopied(false);
      setPlayer(false);

      const constraints: any = {
        video: {
          width: layout.current.offsetWidth, height: layout.current.offsetHeight
        }
      };

      if(selectedCamera) {
        constraints.video.deviceId = { exact: selectedCamera };
      } else {
        cameras.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedCamera(c.value));
        constraints.video.facingMode = 'environment';
      }

      if(!selectedMic) {
        mics.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedMic(c.value));
      }

      navigator.mediaDevices.getUserMedia({video: constraints.video, audio: false}).then(stream => {
        console.log("Successfully received user media stream 1.");
        setLocalStream2(stream);
        // @ts-ignore
        navigator.mediaDevices.getDisplayMedia({video: {mediaSource: "screen"}, audio: false}).then((desktopStream: any) => {

          console.log("Successfully received user media stream 2.");
          setLocalStream1(desktopStream);
          resolve(desktopStream);
        }).catch((error: any) => {
          console.error("navigator.getUserMedia error: ", error);
          reset();
          if(params.src === 'wf') {
            sendMessage({ menu: true });
          } else {
            setModalVisibility(true);
          }
          reject();
        });
      }).catch(error => {
        console.error("navigator.getUserMedia error: ", error);

        alert('Please allow camera permssion and refresh page.');
        reject();
      });
    });
  }

  const onWebcamScreenOptionClick = () => {
    // if(!cameras.length) {
    //   alert('No camera found!');
    // } else {
      setWebcamScreenRecording(true);
      setModalVisibility(false);
      setPlayer(false);
      setLink('');
      prepareWebcamScreenRecording();
    // }
  }

  const onWebcamScreenRecordClick = (): any=> {
    if(!state || state === 'completed') {
      // @ts-ignore
      setRecordingState(true);
      const constraints: any = {};
      if(mute) {
        constraints.audio = false;
      } else {
        if(selectedMic) {
          constraints.audio = {
            deviceId: { exact: selectedMic }
          }
        } else {
          constraints.audio = true;
          mics.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedMic(c.value));
        }
      }

      if(constraints.audio) {
        navigator.mediaDevices.getUserMedia({ video: false, audio: constraints.audio }).then((voiceStream) => {

          const tracks = [ ...uploadStream.getVideoTracks(), ...mergeStreams(uploadStream, voiceStream) ];

          const stream: any = new MediaStream(tracks);

          setAudioStream(voiceStream);

          startRecordingInternal(stream);

        }).catch((error: any) => {
          alert('Please allow microphone permssion then refresh page.');
          console.error("navigator.getUserMedia error: ", error);
        });
      } else {
        startRecordingInternal(uploadStream);
      }

    } else {

      if(params.src == 'wf') {
        const message = JSON.stringify({
          source: 'vumu-embed',
          payload: {
            shareLink: window.location.origin + '/share/' + encodeURIComponent(s3Key.replace('.webm', ''))
          }
        });
        window.parent.postMessage(message, '*');
      } else {
        setRecordingState(false);
      }

      video.current.srcObject = null;
      video2.current.srcObject = null;
      stopStopwatch();
      setState('busy');
      localStream1.getTracks().forEach((track: any) => track.stop());
      localStream2.getTracks().forEach((track: any) => track.stop());
      uploadStream.getTracks().forEach((track: any) => track.stop());
      if(mediaRecorder.state !== 'inactive') mediaRecorder.stop();
      setPlayer(true);
      setTimeout(exportVideo, 0);
    }
  }

  const onRerecordWebcamScreen = () => {
    stopStopwatch();
    setState('');
    prepareWebcamScreenRecording();
  }

  const mergeStreams = (desktopStream: any, voiceStream: any) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return (hasDesktop || hasVoice) ? destination.stream.getAudioTracks() : [];
  };

  const countPromise = (): any => {
    return new Promise((resolve) => {
      setCounter(3);
      setTimeout(() => {
        setCounter(2);
        setTimeout(() => {
          setCounter(1);
          setTimeout(() => {
            setCounter(0);
            resolve(true);
          }, 1000);
        }, 1000);
      }, 1000);
    });
  }

  const startStopwatch = (): any => {
    const start = Date.now();
    setStopwatch('00:00');

    stopwatchInterval = setInterval(() => {
      setStopwatch(formatStopwatchMilliseconds(Date.now() - start));
    }, 1000);

    return stopwatchInterval;
  }

  const stopStopwatch = () => {
    clearInterval(stopwatchInterval);
    setStopwatch('00:00');
  }

  const formatStopwatchMilliseconds = (millisec: number) => {
    let seconds: any = (millisec / 1000).toFixed(0);
    let minutes: any = Math.floor(seconds / 60);
    let hours: any = "";
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = (hours >= 10) ? hours : "0" + hours;
      minutes = minutes - (hours * 60);
      minutes = (minutes >= 10) ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : "0" + seconds;
    minutes = (minutes >= 10) ? minutes : "0" + minutes;

    if (hours !== "") {
      return hours + ":" + minutes + ":" + seconds;
    }
    return minutes + ":" + seconds;
  }

  const startRecordingInternal: any = (stream: any) => {
    // Getting the MediaRecorder instance.
    // I took the snippet from here: https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/record/js/main.js
    if(window.AWS) {
      const AWS = window.AWS;
      window.AWS.config.update(credentials);
      if(!s3) s3 = new AWS.S3();

      chunks.length = 0;
      countPromise().then(() => {

        let options = { mimeType: "video/webm;codecs=opus,vp8" };
        const MediaRecorder: any = window?.MediaRecorder;
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.log(options.mimeType + " is not Supported");
          options = { mimeType: "video/webm;codecs=vp9" };

          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(options.mimeType + " is not Supported");
            options = { mimeType: "video/webm;codecs=vp8" };

            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
              console.log(options.mimeType + " is not Supported");
              options = { mimeType: "video/webm" };
              if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.log(options.mimeType + " is not Supported");
                options = { mimeType: "video/mp4" };
                if(!MediaRecorder.isTypeSupported(options.mimeType)) {
                  console.log(options.mimeType + " is not Supported");
                  options = { mimeType: "" };
                }
              }
            }
          }
        }

        try {
          mediaRecorder = new MediaRecorder(stream, options);
          setState('busy');
        } catch (e) {
          console.error("Exception while creating MediaRecorder: " + e);

          return;
        }

        startStopwatch();

        //Generate the file name to upload. For the simplicity we're going to use the current date.
        const extension = 'webm';
        const id: any = objectId();

        s3Key = `${id}.${extension}`;

        const _params = {
          Bucket: config.BUCKET_NAME,
          Key: s3Key
        };

        const key: string = s3Key;

        // We are going to handle everything as a chain of Observable operators.


        //const obs = Observable<any>;

        obs = from(s3.createMultipartUpload(_params).promise())
          .pipe(
            switchMap((data: any) => {
              // Save the uploadId as we'll need it to complete the multipart upload.
              uploadId = data.UploadId;
              mediaRecorder.start(120000);
              setState('active');

              // Then track all 'dataavailable' events. Each event brings a blob (binary data) with a part of video.
              return fromEvent(mediaRecorder, "dataavailable");
            })
          )



          // Track the dataavailable event until the 'stop' event is fired.
          // MediaRecorder emits the "stop" when it was stopped AND have emitted all "dataavailable" events.
          // So we are not losing data. See the docs here: https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/stop
          .pipe(takeUntil(fromEvent(mediaRecorder, "stop")))
          .pipe(map((event: any, index: number) => {
            // Show how much binary data we have recorded.

            setBytesRecorded(bytesRecorded + event.data.size);
            chunks.push(event.data);

            // Take the blob and it's number and pass down.
            return { blob: event.data, partNumber: index + 1 };
          }))
          // This operator means the following: when you receive a blob - start uploading it.
          // Don't accept any other uploads until you finish uploading: http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#instance-method-concatMap
          .pipe(concatMap(({ blob, partNumber }) => {
            return (
              s3
                .uploadPart({
                  Body: blob,
                  Bucket: config.BUCKET_NAME,
                  Key: s3Key,
                  PartNumber: partNumber,
                  UploadId: uploadId,
                  ContentLength: blob.size
                })
                .promise()
                // Save the ETag as we'll need it to complete the multipart upload
                // @ts-ignore
                .then(({ ETag }) => {
                  // How how much bytes we have uploaded.
                  setBytesUploaded(bytesUploaded + blob.size);
                  return { ETag, PartNumber: partNumber };
                })
            );
          }))
          // Wait until all uploads are completed, then convert the results into an array.
          .pipe(toArray())
          // Call the complete multipart upload and pass the part numbers and ETags to it.

          .pipe(switchMap(parts => {
            return s3.completeMultipartUpload({
              Bucket: config.BUCKET_NAME,
              Key: s3Key,
              UploadId: uploadId,
              MultipartUpload: {
                Parts: parts
              }
            })
              .promise();
          }))

          .subscribe(
            // @ts-ignore
            ({ Location }) => {
              // completeMultipartUpload returns the location, so show it.
              // ensure s3 key is same.
              if(s3Key !== key) return;

                  // Uploaded Successfully.
                  if(Location) {
                    console.log('Video Saved!' + Location);

                    setPlayer(true);
                    if(video.current) {
                      video.current.srcObject = null;
                      setLocalStream1(null);
                      setAudioStream(null);
                    }

                    if(video2.current) {
                      video2.current.srcObject = null;
                      setLocalStream2(null);
                    }

                    setUploadStream(null);

                    setState('completed');


                    // send a server call to save video in db.
                    const urlForCall = params.replaceWith ? '/update-recording' : '/add-recording';
                    const payload = params.replaceWith ? {
                      _id: params.replaceWith,
                      videoUrl: `${config.STREAM_URL}/${s3Key}`,
                      orignalUrl: `${config.STREAM_URL}/${s3Key}`,
                      mp4Url: ""
                    } : {
                      _id: id,
                      videoUrl: `${config.STREAM_URL}/${s3Key}`,
                      createdBy: currentUserId || null,
                    }
                    callApi({ url: urlForCall, payload , method: 'POST' }).then((res) => {
                      if(res) {
                        if (params.replaceWith) {
                          sendMessage({ type: 'RE_RECORD_VIDEO_SUCCES', closeReRecord: true, response: res });
                          return;
                        }
                        if(params.src !== 'wf')
                          window.top.location.href = config.REDIRECTION_UPON_COMPLETION + encodeURIComponent(id);
                        
                      }
                      
                    }).catch((err) => {
                      console.log(err);
                    });

                    if(isFirefox) {
                      setPlayerSource(`${config.STREAM_URL}/${s3Key}`);
                    }
                    console.log("Uploaded successfully.");
                  }
                },
                err => {
                  if(s3Key !== key) return;
                  console.error(err);
                  // clearTimeout();
                  stopStopwatch();
                  setPlayer(true);

                  setLocalStream1(null);
                  setLocalStream2(null);
                  setAudioStream(null);
                  setUploadStream(null);
                  setPlayerSource('');

                  chunks.length = 0;

                  if (uploadId) {
                    // Aborting the Multipart Upload in case of any failure.
                    // Not to get charged because of keeping it pending.
                    setState('busy');
                    s3
                        .abortMultipartUpload({
                          Bucket: config.BUCKET_NAME,
                          UploadId: uploadId,
                          Key: s3Key
                        })
                        .promise()
                        .then(() => {
                          console.log("Multipart upload aborted")
                          setState('');
                        })
                        .catch((e: any) => {
                          setState('');
                          console.error(e)
                        });
                  } else {
                    setState('');
                  }
                }
            )
      });
    }  else {
      alert("There seems to be some problem while connecting. Please refresh.");
    }
  }

  const objectId = () => {
    return uuidv4().replace(/-/g, '').substr(0, 24).toLowerCase();
  }

  const exportVideo = () => {
    setLocalStream1(null);
    setLocalStream2(null);
    setUploadStream(null);
    setAudioStream(null);
    setPlayer(true);

    if(!isFirefox) {
      const blob = new Blob([ ...chunks ]);
      const url = URL.createObjectURL(blob);
      setPlayerSource(url);
    }

    const url = `${s3Key}`;
    setLink(url);
    // window.localStorage.setItem('vumu-link', config.STREAM_URL + '/' + encodeURIComponent(url));
  }

  const onChooseMicClick = (item: any) => {
    if(!localStream1 && !player) return;
    setSelectedMic(item.value);
    switchMic(item.value);
  }

  const switchMic = (switchTo: string) => {
    // localStream1.getTracks().forEach((track: any) => track.stop());
    // uploadStream.getTracks().forEach((track: any) => track.stop());

    if(webcam) {
      prepareWebcamRecording();
    } else if(screenRecording) {
      prepareScreenRecording();
    } else if( webcamScreenRecording) {
      prepareWebcamScreenRecording();
    }
  }

  const onChooseCameraClick = (item: any) => {
    if(!localStream1 && !player) return;
    setSelectedCamera(item.value);
    switchCamera(item.value);
  }

  const switchCamera = (switchTo: string) => {
    if(webcam) {
      localStream1.getTracks().forEach((track: any) => track.stop());
      prepareWebcamRecording();
    } else if(webcamScreenRecording) {
      localStream2.getTracks().forEach((track: any) => track.stop());
      const constraints: any = {
        video: {
          width: layout.current.offsetWidth, height: layout.current.offsetHeight
        }
      };

      if(selectedCamera) {
        constraints.video.deviceId = { exact: selectedCamera };
      } else {
        cameras.filter((c: any, i: number) => i === 0).forEach((c: any) => setSelectedCamera(c.value));
        constraints.video.facingMode = 'environment';
      }

      navigator.mediaDevices.getUserMedia({video: constraints.video, audio: false}).then(stream => {
        console.log("Successfully received user media stream 1.");
        setLocalStream2(stream);
      }).catch(() => {
        alert('Please allow camera permssion and refresh page.');
      });
    }
  }

  const reset = () => {
    if(state === 'busy' || state === 'active') {
      setAskReset(true);
    } else {
      onResetOk();
    }
  }

  const onResetOk = () => {
    if (obs) obs.unsubscribe();
    setAskReset(false);
    setWebcam(false);
    setScreenRecording(false);
    setWebcamScreenRecording(false);
    stopStopwatch();
    setCopied(false);
    setLink('');
    setRecordingState(null);

    if(video.current) video.current.srcObject = null;
    if(video2.current) video2.current.srcObject = null;

    if(localStream1) localStream1.getTracks().forEach((track: any) => track.stop());
    if(localStream2) localStream2.getTracks().forEach((track: any) => track.stop());
    if(uploadStream) uploadStream.getTracks().forEach((track: any) => track.stop());

    if(layout.current) {
      layout.current.style.width = 'inherit';
      layout.current.style.height = 'inherit';
    }

    setLocalStream1(null);
    setLocalStream2(null);
    setUploadStream(null);
    setAudioStream(null);

    if(mediaRecorder && mediaRecorder.state !== 'inactive') mediaRecorder.stop();
    setState('');

    if(params.src === 'wf') {
      sendMessage({ menu: true });
    } else {
      setModalVisibility(true);
    }
  }

  const onCopyClick = () => {
    copyText.current.select();
    copyText.current.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    document.execCommand("copy");
    setCopied(true);
  }

  const onToggleMute = () => {
    setMute(!mute);
  }

  useEffect(() => {
    if(mute !== undefined) {
      if(webcam) {
        localStream1.getTracks().forEach((track: any) => track.stop());
        prepareWebcamRecording();
      }
    }
    // eslint-disable-next-line
  }, [mute]);

  const onStartRecordingClick = () => {
    // if(!currentUserId) {
    //   alert('Unable to start recording. No User found!');
    // } else {
    if(params.src === 'wf') {
      sendMessage({ menu: true });
    } else {
      setModalVisibility(true);
    }
    // }
  }

  return (
    <div className="App">
      <Provider store={store}>
        <Router basename={'/embed'} >
          <Switch>
            {/*<Route path="/share/:videoId">*/}
            {/*  <Share />*/}
            {/*</Route>*/}
            <Route path="/">
              {
                recordingState !== false ? (
                  <>
                    <section className="video-container">
                    <div className='video-inner-wrapper'>
                      {<div className={classnames({'webcam-video': webcam, 'screen-video': screenRecording, 'webcam-screen-video': webcamScreenRecording, 'blank': !webcam && !screenRecording && !webcamScreenRecording})}>
                        <div className="video-theatre">
                          <div className='layout' ref={layout}>
                            {isMediaRecorderSupported && !webcam && !screenRecording && !webcamScreenRecording && params.src != 'wf' &&
                              <>
                                <a href="https://www.producthunt.com/posts/simple-screen-webcam-recording?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-simple-screen-webcam-recording" rel="noopener noreferrer" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=279648&theme=light&period=daily" alt="Simple screen + webcam recording - Simple screen + webcam recording. Share after recording | Product Hunt" style={{width: '250px', height: '54px', top: '-72px', right: '0px', position: 'absolute'}} width="250" height="54" /></a>
                                <Button id="start-rec" type="primary" value="large" className="emphasize x-large center"
                                  onClick={onStartRecordingClick}><img alt=""/>Start Recording</Button>
                              </>
                            }

                            {!isMediaRecorderSupported && <Paragraph className="no-support">
                              <h1>Your browser doesn't support Media recording.<br />Please use <b>Chrome for best results.</b></h1>
                            </Paragraph>}

                            {/*- WEBCAM -------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                            {webcam && <>
                              {localStream1 &&
                                <>
                                  <video autoPlay muted ref={video} className="hidden" onPlay={(e) => drawStreams({target: e.target, flip: true}, undefined)}></video>
                                  <canvas className="upload-stream" ref={canvas}></canvas>
                                </>
                              }
                              {player && playerSource && <video className="player" onPlay={() => setTogglePlayPause(true)} onPause={() => setTogglePlayPause(false)} onEnded={() => setTogglePlayPause(false)} controls src={playerSource}></video>}
                              {(localStream1 || player) && <div className="controls">
                                {localStream1 && <div>
                                  <div className="settings">
                                    <Dropdown overlay={
                                      <Menu title="Settings">
                                        {mics.length && <Menu.ItemGroup title="Microphone">
                                          <SubMenu title={`${mics.find((c: any) => c.value === selectedMic)?.label}`}>
                                            {mics.map((item: any) => (
                                                <Menu.Item key={item.value} onClick={() => onChooseMicClick(item)}>
                                                  {selectedMic === item.value ? <CheckCircleOutlined/> : ''} {item.label}
                                                </Menu.Item>
                                            ))}
                                          </SubMenu>
                                        </Menu.ItemGroup>}
                                        {cameras.length && <Menu.ItemGroup title="Camera">
                                          <SubMenu title={`${cameras.find((c: any) => c.value === selectedCamera)?.label}`}>
                                            {cameras.map((item: any) => (
                                                <Menu.Item onClick={() => onChooseCameraClick(item)} key={item.value}>
                                                  {selectedCamera === item.value ? <CheckCircleOutlined/> : ''} {item.label}
                                                </Menu.Item>))}
                                          </SubMenu>
                                        </Menu.ItemGroup>}
                                      </Menu>
                                    } placement="topLeft">
                                      <Tooltip title="Settings" placement="bottom">
                                        <SettingOutlined />
                                      </Tooltip>
                                    </Dropdown>
                                  </div>
                                  {(!state || state === 'completed') && <div onClick={onToggleMute} className="mute">{mute ? <AudioMutedOutlined/> :
                                    <AudioOutlined />}
                                  </div>}
                                    <Button disabled={state === 'busy'}
                                            className={"record-btn " + state}
                                            onClick={onWebcamRecordClick}
                                            type="primary" shape="circle">
                                    </Button>

                                    <div className="time-elapsed">{stopwatch}</div>
                                  {
                                    (state === 'active' || state === 'busy') && <div className="re-record-btn" onClick={onResetOk}>Rerecord</div>
                                  }
                                </div>}
                                {player && <React.Fragment>
                                  {params.src === 'wf' && <div className="control-actions">
                                    {link && <Button className="download gm" size="large" type="ghost"
                                                     href={`${config.STREAM_URL}/${link}`}
                                                     icon={<CloudDownloadOutlined/>}>Download</Button>}
                                    {link && params.src === 'wf' && <Button className="re-record gm" size="large" onClick={() => alert('Comming soon!')}
                                                                            type="primary" icon={<EditOutlined />}>Edit in Studio</Button>}

                                    <Button className="re-record gm" onClick={onRerecordWebcam} size="large"
                                            type="ghost" icon={<ReloadOutlined/>}>Re-record</Button>
                                  </div>}
                                  {playerSource && !togglePlayPause && <button className="play-btn">
                                    <svg fill="none" height="96" width="96"><path clipRule="evenodd" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" fill="#fff" fillRule="evenodd"></path><path clipRule="evenodd" d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z" fill="#000" fillRule="evenodd"></path></svg>
                                  </button>}

                                </React.Fragment>}
                                {/* <div className="hamburger" onClick={reset}><CloseOutlined /></div> */}
                              </div>}
                            </>}

                            {/*- SCREEN RECORDING ---------------------------------------------------------------------------------------------------------------------------------------------------*/}
                            {screenRecording && <>

                              {localStream1 && <>
                                <video autoPlay muted ref={video} className="hidden" onPlay={(e) => drawStreams({target: e.target, flip: false}, undefined)}></video>
                                <canvas className="upload-stream" ref={canvas}></canvas>
                              </>}
                              {player && playerSource && <video onPlay={() => setTogglePlayPause(true)} className="player" onPause={() => setTogglePlayPause(false)} onEnded={() => setTogglePlayPause(false)} controls src={playerSource}></video>}
                              {(localStream1 || player) && <div className="controls">
                                {localStream1 && <div>
                                  <div className="settings">
                                    <Dropdown overlay={
                                      <Menu title="Settings">
                                        {mics.length && <Menu.ItemGroup title="Microphone">
                                          <SubMenu title={`${mics.find((c: any) => c.value === selectedMic)?.label}`}>
                                            {mics.map((item: any) => (
                                                <Menu.Item key={item.value} onClick={() => onChooseMicClick(item)}>
                                                  {selectedMic === item.value ? <CheckCircleOutlined/> : ''} {item.label}
                                                </Menu.Item>
                                            ))}
                                          </SubMenu>
                                        </Menu.ItemGroup>}
                                      </Menu>
                                    } placement="topLeft">
                                      <Tooltip title="Settings" placement="left">
                                        <SettingOutlined />
                                      </Tooltip>
                                    </Dropdown>
                                  </div>
                                  {(!state || state === 'completed') && <div onClick={onToggleMute} className="mute">{mute ? <AudioMutedOutlined/> :
                                      <AudioOutlined/>}</div>}
                                  <Button disabled={state === 'busy'}
                                          className={"record-btn " + state}
                                          onClick={() => onScreenRecordClick(localStream1)}
                                          type="primary" shape="circle"></Button>

                                  <div className="time-elapsed">{stopwatch}</div>
                                  {
                                    (state === 'active' || state === 'busy') && <div className="re-record-btn" onClick={onResetOk}>Rerecord</div>
                                  }
                                </div>}
                                {player && <React.Fragment>
                                  {params.src === 'wf' && <div className="control-actions">
                                    {link && <Button className="download gm" size="large" type="primary"
                                                     href={`${config.CDN_STREAM_URL}/${link}`}
                                                     icon={<CloudDownloadOutlined/>}>Download</Button>}
                                    {link && params.src === 'wf' && <Button className="re-record gm" size="large" onClick={() => alert('Comming soon!')}
                                                                            type="primary" icon={<EditOutlined />}>Edit in Studio</Button>}
                                    <Button className="re-record gm" onClick={onRerecordScreen} size="large"
                                            type="primary" icon={<ReloadOutlined/>}>Re-record</Button>
                                  </div>}
                                  {playerSource && !togglePlayPause && <button className="play-btn">
                                    <svg fill="none" height="96" width="96"><path clipRule="evenodd" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" fill="#fff" fillRule="evenodd"></path><path clipRule="evenodd" d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z" fill="#000" fillRule="evenodd"></path></svg>
                                  </button>}

                                </React.Fragment>}
                                {/* <div className="hamburger" onClick={reset}><CloseOutlined /></div> */}
                              </div>}
                            </>}

                            {/*- WEBCAM + SCREEN ----------------------------------------------------------------------------------------------------------------------------------------------------*/}
                            {webcamScreenRecording && <>
                              {localStream2 && <video className="rounded rounded-circle" autoPlay muted ref={video2}></video>}
                              {localStream1 && <>
                                <video autoPlay muted ref={video} className="hidden" onPlay={(e) => drawStreams({target: e.target, flip: false}, {target: video2.current, flip: true})}></video>
                                <canvas className="upload-stream" ref={canvas}></canvas>
                              </>}
                              {player && playerSource && <video onPlay={() => setTogglePlayPause(true)} className="player" onPause={() => setTogglePlayPause(false)} onEnded={() => setTogglePlayPause(false)} controls src={playerSource}></video>}
                              {(localStream2 || localStream1 || player) && <div className="controls">
                                {localStream2 && <div>
                                  <div className="settings">
                                    <Dropdown overlay={
                                      <Menu title="Settings">
                                        {mics.length && <Menu.ItemGroup title="Microphone">
                                          <SubMenu title={`${mics.find((c: any) => c.value === selectedMic)?.label}`}>
                                            {mics.map((item: any) => (
                                                <Menu.Item key={item.value} onClick={() => onChooseMicClick(item)}>
                                                  {selectedMic === item.value ? <CheckCircleOutlined/> : ''} {item.label}
                                                </Menu.Item>
                                            ))}
                                          </SubMenu>
                                        </Menu.ItemGroup>}
                                        {cameras.length && <Menu.ItemGroup title="Camera">
                                          <SubMenu title={`${cameras.find((c: any) => c.value === selectedCamera)?.label}`}>
                                            {cameras.map((item: any) => (
                                                <Menu.Item onClick={() => onChooseCameraClick(item)} key={item.value}>
                                                  {selectedCamera === item.value ? <CheckCircleOutlined/> : ''} {item.label}
                                                </Menu.Item>))}
                                          </SubMenu>
                                        </Menu.ItemGroup>}
                                      </Menu>
                                    } placement="topLeft">
                                      <Tooltip title="Settings" placement="right">
                                        <SettingOutlined />
                                      </Tooltip>
                                    </Dropdown>
                                  </div>
                                  {(!state || state === 'completed') && <div onClick={onToggleMute} className="mute">{mute ? <AudioMutedOutlined/> :
                                      <AudioOutlined/>}</div>}
                                  <Button disabled={state === 'busy'}
                                          className={"record-btn " + state}
                                          onClick={onWebcamScreenRecordClick}
                                          type="primary" shape="circle">
                                  </Button>

                                  <div className="time-elapsed">{stopwatch}</div>
                                  {
                                    (state === 'active' || state === 'busy') && <div className="re-record-btn" onClick={onResetOk}>Rerecord</div>
                                  }
                                </div>
                                }
                                {player && <React.Fragment>
                                  {params.src === 'wf' && <div className="control-actions">
                                    {link && <Button className="download gm" size="large" type="ghost"
                                                     href={`${config.CDN_STREAM_URL}/${link}`}
                                                     icon={<CloudDownloadOutlined/>}>Download</Button>}

                                    {link && params.src === 'wf' && <Button className="re-record gm" size="large" onClick={() => alert('Comming soon!')}
                                                                            type="primary" icon={<EditOutlined />}>Edit in Studio</Button>}
                                    <Button className="re-record gm"
                                            onClick={onRerecordWebcamScreen} size="large" type="ghost"
                                            icon={<ReloadOutlined/>}>Re-record</Button>
                                  </div>}
                                  {playerSource && !togglePlayPause && <button className="play-btn">
                                    <svg fill="none" height="96" width="96"><path clipRule="evenodd" d="M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z" fill="#fff" fillRule="evenodd"></path><path clipRule="evenodd" d="M37.326 33.822c0-2.408 2.695-3.835 4.687-2.481l20.862 14.178c1.752 1.19 1.752 3.772 0 4.963L42.013 64.66c-1.992 1.354-4.687-.072-4.687-2.48V33.821z" fill="#000" fillRule="evenodd"></path></svg>
                                  </button>}

                                </React.Fragment>}
                                {/* <div className="hamburger" onClick={reset}><CloseOutlined /></div> */}
                              </div>}
                            </>}
                          </div>
                        </div>
                      </div>}
                      <Modal
                        title="Pick a layout"
                        visible={modalVisibility}
                        centered
                        footer={null}
                        className="options-modal"
                        width={1000}
                        onCancel={() => setModalVisibility(false)}
                        maskClosable={!panel}>

                        <Card
                          onClick={onScreenOptionClick}
                          hoverable
                          style={{ width: 240 }}
                          cover={<i className="screen-only"></i>}>
                          <Meta title="SCREEN" description="" />
                        </Card>

                        <Card
                          onClick={onWebcamOptionClick}
                          hoverable
                          style={{ width: 240 }}
                          cover={<i className="camera-only"></i>}>
                            <Meta title="WEBCAM" description="" />
                        </Card>

                        <Card
                            onClick={onWebcamScreenOptionClick}
                            hoverable
                            style={{ width: 240 }}
                            cover={<i className="both"></i>}>
                          <Meta title="WEBCAM + SCREEN" description="" />
                        </Card>
                      </Modal>
                      {link && params.src != 'wf' && <section className="video-info">
                        <Input ref={copyText} className="video-link" suffix={<Button style={{color: '#1a90ff'}} type="text" onClick={onCopyClick} icon={<CopyOutlined />}>{copied ? 'Copied!' : 'Copy'}</Button>} size="large"  prefix={<>Share link:</>} defaultValue={window.location.origin + '/share/' + encodeURIComponent(link.replace('.webm', ''))} />
                      </section>}
                    </div>
                    </section>
                  </>
                ) : <>

                  <LoadingComponent />
                  </>
              }
              {/* <Timeline mode='left' className='ant-timeline' pending={(state === 'active' || state === 'busy') && <CloudUploadOutlined />}></Timeline> */}
              <Modal title="Upload is in progress" visible={askReset} onOk={onResetOk} onCancel={() => setAskReset(false)}>
                <Paragraph>The video is being saved. Click Ok if you want to unsave?</Paragraph>
              </Modal>
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
      {!!counter && <div className={'theatre-counter-overlay'}>{counter}</div>}
    </div>
  );
}

export default App;
