export class UserModel {
  //Fields 
  userId: string;
  userName: string;
  fullName: string;
  parentAccount: string;
  subscription: string;
  email: string;
  emailVerified: boolean;
  picture: string;
  sub: string;
  jwtToken: string;
  onBoard: boolean;
  groups: Array<string> = [];
  packages: undefined | string;
  
  constructor (data: any, token: any){
    this.userId = data['preferred_username']
    this.userName = data['sub']
    this.fullName = data['name']
    this.parentAccount = data['custom:parentAccount']
    this.subscription = data['custom:subscription']
    this.packages = data['custom:packages']
    this.email = data['email']
    this.emailVerified = data['email_verified']
    this.picture = data['picture']
    this.sub = data['sub']
    this.onBoard = data['custom:onBoard'] || true
    this.groups = data.idToken.payload['cognito:groups'];
    this.jwtToken = token
  }}