import { SET_PROFILE } from './types';
import { Reducer } from "redux";
import { profileType } from './DataProps';

interface actionType {
  type: string,
  payload?: any
}

interface initalStateProps {
  isAuthenticated: boolean;
  profile: profileType | null;
}
const initState: initalStateProps = {
  isAuthenticated: true,
  profile: null
}

const authReducer: Reducer<initalStateProps, actionType> = (state = initState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      console.log('setting profile')
      return Object.assign({}, state, {
        isAuthenticated: true,
        profile: action.payload
      })

    default:
      return state;
  }
}

export default authReducer;