export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const LOADED_SUCCESS = 'LOADED_SUCCESS';
export const LOADING_APP = 'LOADING_APP';


interface IProps {
  ADD_ACCOUNT_REQUEST: typeof SOCKET_CONNECTED;
  ADD_ACCOUNT_SUCCESS: typeof LOADED_SUCCESS;
  LOADING_APP: typeof LOADING_APP;
}

export type actionType = IProps