import apiUrl from '../config';
import { AxiosRequestConfig } from 'axios';
import { getUToken } from './auth.helper';
import { message } from 'antd';
import { axiosApi, axiosApiGateway } from './interceptor';

interface IProps {
  url: AxiosRequestConfig['url'];
  payload: AxiosRequestConfig['data'];
  method: AxiosRequestConfig['method'];
  token?: string;
}

export const callApi = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
    await callApiWithToken({ url, payload, method, token }) :
    await callApiWithoutToken({ url, payload, method })
}

export const callApiGateway = async ({ url, payload, method }: IProps) => {
  const token = getUToken();
  return token ?
    await callApiGatewayWithToken({ url, payload, method, token }) :
    await callApiGatewayWithoutToken({ url, payload, method })
}


export const callApiWithToken = async ({ url, payload, method, token }: IProps) => {
  return method === 'GET' ?
    await axiosApi({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApi({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callApiWithoutToken = async ({ url, payload, method }: IProps) => {
  return method === 'GET' ?
    await axiosApi({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApi({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callApiGatewayWithToken = async ({ url, payload, method, token }: IProps) => {
  return method === 'GET' ?
    await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}

export const callApiGatewayWithoutToken = async ({ url, payload, method }: IProps) => {
  return method === 'GET' ?
    await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((res: any) => {
      return res.data;
    }).catch((err: any) => {
      message.error("something went wrong");
    }) : await axiosApiGateway({
      url: url,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((res: any) => {
      return res;
    }).catch((err: any) => {
      message.error("something went wrong");
    });
}